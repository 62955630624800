import {onFind} from "@elements/init-modules-in-scope";
import './scripts/common';

import 'bootstrap/js/dist/collapse';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({
    arrowIconClass: 'icon icon-arrow-down-light'
});

import * as video from './scripts/video';
video.init();

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as lightbox from '@elements/lightbox';
lightbox.init();